import React from "react"

import { Form } from "../components/Form"
import { Helmet } from "react-helmet"

const AboutPage = () => (
  <>
    <Helmet>
      <title>Contact | Fraser Community Child Care Centre & Out of School Care</title>
      <link rel="canonical" href="https://frasercommunitychildcarecenter.com/contact" />
    </Helmet>
    <div className="grid">
      <section className="grid__item section">
        <h2 className="heading heading--section heading--no-skew text--centered">Get In Touch</h2>
        <p className="body-text text--centered">
          Please complete the form. We will get back to you as soon as we can!
        </p>
        <Form />
      </section>
    </div>
  </>
)

export default AboutPage
