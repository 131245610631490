import React from "react"

const FormGroup = ({ name, onChange, placeholder, type, value }) => {
  function renderInput() {
    return (
      <input
        type={type}
        className="form__input"
        id={name}
        name={name}
        required
        value={value}
        onChange={onChange}
      />
    )
  }

  function renderTextArea() {
    return (
      <textarea
        className="form__input"
        id={name}
        name={name}
        rows={8}
        maxLength={999}
        required
        value={value}
        onChange={onChange}
      />
    )
  }

  const nameCapitalized = name.charAt(0).toUpperCase() + name.slice(1)

  return (
    <div className="form__group">
      {type === "textarea" ? renderTextArea() : renderInput()}
      <label htmlFor={name} className="form__label">
        {nameCapitalized}
      </label>
    </div>
  )
}

export default FormGroup